// 引入 axios
import axios from "axios";



let base = 'https://www.xuanjierui.icu/api/web/';
// let base = 'http://localhost:8089/api/web/';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
